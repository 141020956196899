







import { Component, Vue } from "vue-property-decorator";

@Component({
    name: "Error",
    props: {
        errorId: Number,
    },
})
export default class Error extends Vue {
    // private mounted() { }
    // private data(): object { return { } }

    private get ErrorMsg(): string  {
        switch (this.$props.errorId) {
            case 404:
                return "404 Not Found";
            default:
                return "Internal Error";
        }
    }

    private goHome(): void {
        this.$router.replace({ name: "home" });
    }
}
