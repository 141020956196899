var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "error" }, [
    _c("h1", [_vm._v(_vm._s(_vm.ErrorMsg))]),
    _c(
      "button",
      {
        on: {
          click: function ($event) {
            return _vm.goHome()
          },
        },
      },
      [_vm._v("Home")]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }